<template>
  <div>
    <el-form
      :model="login"
      :rules="rules"
      ref="payInfo"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="账号"
        prop="phoneOrEmail"
        placeholder="账号为邮箱或者手机号"
      >
        <el-input v-model="login.phoneOrEmail" style="width: 200px" />
      </el-form-item>
      <el-form-item label="密码" prop="password" placeholder="请输入密码">
        <el-input show-password v-model="login.pwd" style="width: 200px" />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="createForm(login)"
          v-loading.fullscreen.lock="fullscreenLoading"
          >登录
        </el-button>
        <el-button @click="toRegister">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      loginStatus: (state) => state.Account.loginStatus,
      token: (state) => state.Account.token,
    }),
  },
  data() {
    return {
      login: {
        phoneOrEmail: "",
        pwd: "",
      },
      rules: {
        phoneOrEmail: [
          {
            required: true,
            message: "请输入账号",
            trigger: "clear",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "clear",
          },
        ],
      },
      fullscreenLoading: false,
    };
  },
  methods: {
    createForm(login) {
      if (this.token === "") {
        this.$store.dispatch("login", login);
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
          if (this.loginStatus) {
            this.$store.dispatch("accountInfo");
            this.$store.commit("LOGON_SPAN", "UNLOCK");
            this.$router.push({ name: "accountInfo" });
          }
        }, 1000);
      } else {
        this.$message.error("你已登录!!!");
      }
    },
    toRegister() {
      this.$router.push({ name: "sendCode" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
